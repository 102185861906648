import React from 'react';
import './Logo.scss';
import { ReactComponent as LogoSvg } from './Logo.svg';

function Logo() {
  return (
    <div className="Logo">
      <div className="background">
        <LogoSvg />
      </div>
    </div>
  );
}

export default Logo;
