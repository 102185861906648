import React from 'react';
import './IconSocialTwitter.scss';
import { ReactComponent as Icon } from './IconSocialTwitter.svg';

function IconSocialTwitter() {
  return (
    <div className="IconSocialTwitter">
      <Icon />
    </div>
  );
}

export default IconSocialTwitter;
