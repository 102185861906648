import React from 'react';
import { Link } from 'react-router-dom';
import ContentStack from '../../ui-components/ContentStack/ContentStack';
import Heading from '../../ui-components/Heading/Heading';
import './HomePage.scss';

function HomePage() {
  return (
    <div className="home-page">

      <ContentStack
        primaryContent={
          <div>
            <Heading>
              Striketype is a Creative Studio for the Web
            </Heading>

            <p><b>At Striketype &mdash; the digital creative studio led by Ed Brissenden &mdash; we specialising in creating websites, logos and branding.</b></p>
            {/* <Link to={'/apps'}>apps</Link>  */}

            <p>We also design digital art and assets for sale on <Link to={{ pathname: "https://www.etsy.com/uk/shop/Striketype" }} target="_blank">Etsy</Link> and our own brand of t-shirts for sale on <Link to={{ pathname: "https://striketype.teemill.com" }} target="_blank">Teemill</Link>.</p>
            {/* <p>Our most notable release is the collaborative to-do app <Link to={'/apps/tasks'}>Tasks by Striketype</Link>, and other popular releases include adventure game <Link to={'/apps/bear-left'}>Bear Left</Link> and puzzle game <Link to={'/apps/colour-heist'}>Colour Heist</Link>.</p> */}

            {/* <p>Our popular releases include adventure game <Link to={'/apps/bear-left'}>Bear Left</Link> and puzzle game <Link to={'/apps/colour-heist'}>Colour Heist</Link>.</p> */}

            <p>Please <Link to={'/contact'}>contact us</Link> for new enquiries.</p>
          </div>
        }
        secondaryContent={
          null
          // <img src={require("../../../images/iphone-striketype-blur.png")} width={340} alt="iPhone" />
        }
      />

    </div>
  );
}

export default HomePage;
