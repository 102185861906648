import React from 'react';
import './IconSocialInstagram.scss';
import { ReactComponent as Icon } from './IconSocialInstagram.svg';

function IconSocialInstagram() {
  return (
    <div className="IconSocialInstagram">
      <Icon />
    </div>
  );
}

export default IconSocialInstagram;
