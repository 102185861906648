import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.scss';
import HomePage from './components/pages/HomePage/HomePage';
import Menu from './components/ui-components/Menu/Menu';
import Footer from './components/ui-components/Footer/Footer';
// import AppsPage from './components/pages/AppsPage/AppsPage';
// import PrivacyPage from './components/pages/PrivacyPage/PrivacyPage';
import ContactPage from './components/pages/ContactPage/ContactPage';
import ScrollToTop from './components/ui-components/ScrollToTop/ScrollToTop';
import PageTitle from './components/ui-components/PageTitle/PageTitle';

function App() {
  return (
    <Router>

      <ScrollToTop />

      <Menu></Menu>

      <div className="App">

        <div className="app-body">

          <Switch>
            {/* Apps */}
            {/* <Route path="/apps">
              <PageTitle title="Striketype | Apps">
                <AppsPage />
              </PageTitle>
            </Route> */}
            {/* Contact */}
            <Route path="/contact">
              <PageTitle title="Striketype | Contact">
                <ContactPage />
              </PageTitle>
            </Route>
            {/* Privacy */}
            {/* <Route path="/privacy">
              <PageTitle title="Striketype | Privacy">
                <PrivacyPage />
              </PageTitle>
            </Route> */}
            {/* Home */}
            <Route path="/">
              <PageTitle title="Striketype">
                <HomePage />
              </PageTitle>
            </Route>
          </Switch>

        </div>

        <Footer />

      </div>

    </Router>
  );

}

export default App;
