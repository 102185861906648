import React from 'react';
import './Heading.scss';

function Heading(props: any) {
  return (
    <div className="heading">
      {props.children}
    </div>
  );
}

export default Heading;
