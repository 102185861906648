import React from 'react';
import { Link } from 'react-router-dom';
// import ApiVersion from '../ApiVersion/ApiVersion';
import ContentStack from '../ContentStack/ContentStack';
import IconSocialDribbble from '../IconSocialDribbble/IconSocialDribbble';
import IconSocialInstagram from '../IconSocialInstagram/IconSocialInstagram';
import IconSocialTwitter from '../IconSocialTwitter/IconSocialTwitter';
import './Footer.scss';

function Footer() {

  return (
    <div className="footer">

      {/* <div className="content"> */}

      <ContentStack
        primaryContent={
          <div>

            <p className="title">Striketype</p>

            <div className="social">
              <div className="icon">
                <IconSocialTwitter />
              </div>
              <div className="text">
                <div className="app">
                  Twitter</div>
                <div className="link">
                  <Link to={{ pathname: "https://twitter.com/striketype" }} target="_blank">@striketype</Link>
                </div>
              </div>
            </div>

            <div className="social">
              <div className="icon">
                <IconSocialInstagram />
              </div>
              <div className="text">
                <div className="app">
                  Instagram</div>
                <div className="link">
                  <Link to={{ pathname: "https://instagram.com/striketypeco" }} target="_blank">@striketypeco</Link>
                </div>
              </div>
            </div>

            <div className="social">
              <div className="icon">
                <IconSocialDribbble />
              </div>
              <div className="text">
                <div className="app">
                  Dribbble</div>
                <div className="link">
                  <Link to={{ pathname: "https://dribbble.com/striketype" }} target="_blank">@striketype</Link>
                </div>
              </div>
            </div>

            <div className="copyright">&copy; 2019-{new Date().getFullYear()} Striketype</div>

            <div className="company">Striketype Ltd. Registered company number 09256731.</div>

            {/* <div className="api"><ApiVersion></ApiVersion></div> */}

          </div>
        }
        secondaryContent={
          null
        }
      />

      {/* </div> */}

    </div>
  );

}

export default Footer;
