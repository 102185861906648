import React from 'react';
import { Link } from 'react-router-dom';
import ContentStack from '../../ui-components/ContentStack/ContentStack';
import Heading from '../../ui-components/Heading/Heading';
import './ContactPage.scss';

function ContactPage() {
  return (
    <div className="contact-page">

      <ContentStack
        primaryContent={
          <div>
            <Heading>
              Contact
            </Heading>

            <p>The best way to contact us is via <Link to={{ pathname: "https://instagram.com/striketypeco" }} target="_blank">Instagram</Link> or <Link to={{ pathname: "https://twitter.com/striketype" }} target="_blank">Twitter</Link>.</p>
          </div>
        }
        secondaryContent={
          null
        }
      />

    </div>
  );
}

export default ContactPage;
