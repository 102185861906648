import React from 'react';
import './IconSocialDribbble.scss';
import { ReactComponent as Icon } from './IconSocialDribbble.svg';

function IconSocialDribbble() {
  return (
    <div className="IconSocialDribbble">
      <Icon />
    </div>
  );
}

export default IconSocialDribbble;
