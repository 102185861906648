import React from 'react';
import './IconContact.scss';
import { ReactComponent as Icon } from './IconContact.svg';

function IconContact() {
  return (
    <div className="IconContact">
      <Icon />
    </div>
  );
}

export default IconContact;
