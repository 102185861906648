import React from 'react';
import './IconHome.scss';
import { ReactComponent as Icon } from './IconHome.svg';

function IconHome() {
  return (
    <div className="IconHome">
      <Icon />
    </div>
  );
}

export default IconHome;
