import { useEffect } from "react";

const PageTitle = (props: {
  title: string;
  children: any;
}) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  return props.children;
};

export default PageTitle;