// V2

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import IconApps from '../IconApps/IconApps';
import IconContact from '../IconContact/IconContact';
import IconHome from '../IconHome/IconHome';
// import IconPrivacy from '../IconPrivacy/IconPrivacy';
import Logo from '../Logo/Logo';
import './Menu.scss';

export type PageName = 'home' | 'contact'; // | 'privacy'| 'apps'

export interface MenuState {
  page: PageName;
}

function Menu() {

  console.log('Menu()');

  // Declare a new state variable, "page"
  const [page, setPage] = useState('/');

  // V2 improve efficiency

  const location = useLocation();
  // const locationPathname = location.pathname;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    console.log('Menu useEffect()');

    // Set page if location is set on load (for example reloading '/privacy')
    const locationPathname = location.pathname;
    let locationDirectories = locationPathname.split('/');
    locationDirectories = locationDirectories.length!! ? locationDirectories.filter(locationDirectory => locationDirectory !== '') : [];
    let locationDirectory = '';

    // console.log('locationPathname', locationPathname);
    // console.log('locationDirectories', locationDirectories);
    // setPage(locationDirectory);

    if (!!locationDirectories?.length) {
      locationDirectory = '/' + locationDirectories[0];
      // console.log('locationDirectory', locationDirectory);
      if (locationDirectory && locationDirectory !== page) {
        // console.log('setPage()', locationDirectory);
        setPage(locationDirectory);
      }
    }

  }, [location.pathname, page]);
  // }, []);
  // Only re-run the effect if (count) changes
  // You can tell React to skip applying an effect if certain values haven’t changed between re-renders.
  // To do so, pass an array as an optional second argument to useEffect.
  // If you want to run an effect and clean it up only once (on mount and unmount), you can pass an empty array ([]) as a second argument.
  // This tells React that your effect doesn’t depend on any values from props or state, so it never needs to re-run. 

  const items: {
    component: any;
    className: string,
    route: string;
  }[] = [
      {
        component: <Logo />,
        className: 'item-logo',
        route: '/'
      },
      {
        component: <IconHome />,
        className: 'item-home',
        route: '/'
      },
      // {
      //   component: <IconApps />,
      //   className: 'item-apps',
      //   route: '/apps'
      // },
      {
        component: <IconContact />,
        className: 'item-contact',
        route: '/contact'
      },
      // {
      //   component: <IconPrivacy />,
      //   className: 'item-privacy',
      //   route: '/privacy'
      // }
    ];


  return (
    <div className="Menu">
      {items.map((item, index) => {
        return (
          <Link key={'item-' + index} className={'item ' + item.className + ' ' + (item.className !== 'item-logo' && page === item.route ? 'active' : '')} to={item.route} onClick={() => setPage(item.route)}>
            <div className="content">
              {item.component}
            </div>
          </Link>
        )
      })}
    </div>
  );

}

export default Menu;
