import React from 'react';
import './ContentStack.scss';

function ContentStack(props: {
  primaryContent: any, secondaryContent: any
}) {
  return (
    <div className="content-stack">
      <div className="content-stack-primary">
        {/* primaryContent */}
        {props.primaryContent}
      </div>
      <div className="content-stack-secondary">
        {/* secondaryContent */}
        {props.secondaryContent}
      </div>
    </div>
  );
}

export default ContentStack;
